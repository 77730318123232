<template>
  <div :class="styles.root">
    <Typography variant="h2" tag="div" class="mb-4">Sign In</Typography>
    <form :class="styles.wrapper" @submit.prevent="submitLogin">
      <TextField v-model="form.email" class="w-full mb-4" type="email" label="Email:" placeholder="Email" size="large" :errors="errors?.email" />
      <TextField v-model="form.password" class="w-full mb-4" type="password" label="Password:" placeholder="Password" size="large" :errors="errors?.password" />
      <div :class="styles.forgotten">
        <ButtonLink class="text-brown" @click.prevent="changeChangeModal">Forgot your password?</ButtonLink>
      </div>
      <Button :class="styles.button" size="large" type="submit" :disabled="loading">Sign In</Button>
      <div :class="styles.divider">
        <div :class="styles.dividerItem" aria-hidden="true">
          <div :class="styles.dividerLine" />
        </div>
        <div :class="styles.dividerContent">
          <span :class="styles.dividerText">Or</span>
        </div>
      </div>
      <Button :class="styles.button" size="large" type="button" gradient="rainbow-border" @click.prevent="handleRegister">Register</Button>
      <NuxtLink :class="styles.support" :to="routerPaths.support" external>
        <Typography variant="label" tag="span">Contact  with Support</Typography>
      </NuxtLink>
    </form>
  </div>
</template>

<script lang="ts" setup>
import styles from './AuthForm.module.scss';
import { Button, ButtonLink } from '~/shared/ui/Button';
import { TextField } from '~/shared/ui/Form';
import { Typography } from '~/shared/ui/Typography';
import { NuxtLink } from '#components';
import { routerPaths } from '~/shared/config/router';
import { useAuthModal } from '~/features/auth/store/useAuthModal';
import { useLogin } from '../../../composables/useLogin';
import { navigateTo } from '#app';
import { storeToRefs } from 'pinia';

const { changeState, closeModal } = useAuthModal();
const { isModalOpen } = storeToRefs(useAuthModal());

interface AuthFormEmit {
  (e: 'changeLoading', value: boolean): void;
}

const emits = defineEmits<AuthFormEmit>();

const changeChangeModal = () => {
  changeState('forgotten');
}

const handleRegister = async () => {
  await navigateTo(routerPaths.signUp, {
    external: true,
  });
}

const handleCallback = async (): void => {
  if (isModalOpen.value) {
    closeModal();
  } else {
    await navigateTo(routerPaths.main, {
      external: true,
    })
  }
}

const { loading, form, errors, submitLogin } = useLogin(handleCallback);

watch(loading, (newValue) => {
  emits('changeLoading', newValue);
});
</script>