import { ref, type Ref } from 'vue';
import { useApiError } from '~/shared/composables/useApiError';

interface LoginForm {
  email: string;
  password: string;
}

const useLogin = (onCallback: () => void) => {
  const { login } = useSanctumAuth();
  const loading: Ref<boolean> = ref(false);
  const errors = ref<Record<string, string[]>>({});

  const form: Ref<LoginForm> = ref({
    email: '',
    password: '',
  });

  const startLoading = () => {
    loading.value = true;
  }

  const stopLoading = () => {
    loading.value = false;
  }

  const submitLogin = async () => {
    startLoading();
    errors.value = {};

    try  {
      await login(form.value);

      await onCallback();
    } catch (e) {
      // TODO: I think we need to improve error handling.
      const error = useApiError(e);

      if (error.isValidationError) {
        errors.value = error.bag;
      } else {
        alert(error.message);
      }
    } finally {
      stopLoading();
    }
  }

  return {
    submitLogin,
    loading,
    form,
    errors,
  }
}

export { useLogin };